<template>
  <div class="container doneren-header">
    <div class="row" data-aos="fade-up" data-aos-duration="900">
      <div class="col-sm col-12">
        <img :src="donateIllustration" class="image-fluid" />
      </div>
      <div class="col-sm col-10">
        <h2>Door donaties kunnen wij de  wikkelsetjes kosteloos blijven aanbieden</h2>
        <p>
          Daarom zijn zowel geld- als stofdonaties meer dan welkom!
          <br /><br />
          Met donaties kunnen wij mooie wikkelsetjes maken voor stilgeboren kindjes.
          Hiervoor hebben we wel een aantal benodigdheden nodig. Dit zijn o.a. stoffen, naaigaren, verpakkingsmateriaal, en linten. 
          Deze hopen wij te ontvangen via een donatie, of kopen wij van het geld dat we ontvangen. 
          Daarnaast gebruiken wij flyers en visitekaartjes om zo veel mogelijk ouders te bereiken die hier mee te maken krijgen. 
          Verder bekostigen wij ook de verzendkosten voor het versturen van de setjes naar ziekenhuizen, maar ook naar de ouders.
          <br /><br />
          De gehele donatie wordt gebruikt voor Liefs Lune.
          <br /><br />
          Wil je doneren? Heel graag! Vul het formulier in en wij zullen contact opnemen voor het versturen van ons verzendadres of voor het versturen van een Tikkie. 
          <br />
          <small>Momenteel wordt nog gewerkt aan andere manieren voor het maken van een gelddonatie.</small>
        </p>
      </div>
    </div>
  </div>
  <section class="helpen">
    <div class="container">
      <div class="row" data-aos="fade-up" data-aos-duration="900">
        <div class="col-sm col-12">
          <h2>Wat we kunnen gebruiken</h2>
          <ul>
            <li>
              <div>
                <img src="@/assets/img/vink.png" class="img-fluid" />
                Niet-rekbare stoffen (katoen, wafel, hydrofiel) zowel in effen
                kleuren als met printjes
              </div>
            </li>
            <li>
              <div>
                <img src="@/assets/img/vink.png" class="img-fluid" />Fleece,
                teddy, badstof voor de binnenkant, liefst effen
              </div>
            </li>
            <li>
              <div>
                <img src="@/assets/img/vink.png" class="img-fluid" />Lintjes,
                effen (vooral wit)
              </div>
            </li>
          </ul>
        </div>
        <div class="col">
          <h2>Wat we <span>niet</span> kunnen gebruiken</h2>
          <p>
            Doordat deze kindjes vaak te vroeg worden geboren, is de huid nog enorm dun. Daardoor zullen veel kindjes een dieprode kleur en zichtbare bloedvaten hebben. 
            Op het moment dat ze in een wikkeldoek worden gelegd die een rode hoofdkleur heeft, wordt de rode kleur van het kindje versterkt. 
            Daardoor lijkt het kindje nog roder. Om dat te voorkomen hebben wij liever geen rode stoffen. De kleuren roze en oranje hebben hetzelfde effect, maar wel in mindere mate. 
            <br /><br />
            De enige stoffen die we dus liever weigeren, zijn stoffen met <strong>rood als hoofdkleur</strong> (rood in een klein printje is natuurlijk geen probleem).
            <br /><br />
            Als gebruik wordt gemaakt van de watermethode, zal de kleur van het kindje na enige tijd in het water weer lichter kleuren.
          </p>
        </div>
      </div>
    </div>
  </section>

  <section class="doneren-contact">
    <div class="container doneren-sectie-2">
      <div class="row" data-aos="fade-up" data-aos-duration="900">
        <div class="col-12 col-sm-7 contact-form order-2 order-sm-1">
          <contact-form></contact-form>
        </div>
        <div class="col doneren-gegevens order-1 order-sm-2">
          <h2>Doneren</h2>
          <ul>
            <li>
              Vul het formulier in en wij nemen contact op voor het versturen van ons verzendadres of een Tikkie.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import ContactForm from "@/components/ContactForm.vue";
// import { initPayPalButton } from "@/paypal.js";
// import { loadScript } from "@paypal/paypal-js";
// loadScript({ "client-id": "AdnMaMsrfVYwu9ptTuJa0ztVI4IWsbLMS6_9ZfRFbhcMaMhn60kbQPrlLFIvsIjHzMRph1xyhtC2JTud&enable-funding=venmo", currency: "EUR" });

export default {
  components: {
    ContactForm,
  },

  data() {
    return {
      donateIllustration: require("../assets/img/help-illustratie@2x.png"),
    };
  },

  mounted() {
  },
};
</script>
